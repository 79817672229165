$(document).ready(function () {
	let pProps = {
		arrows: false,
		next_Arrow: $('a [data-direction="next"]'),
		rows: 1,
		slidesToShow: 4,
		centerMode: false,
		initialSlide: 0,
		fade: false,
		slidesToScroll: 4,
		responsive: [
			{
				breakpoint: 1441,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 3,
				},
			},
			{
				breakpoint: 1025,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 2,
				},
			},
			{
				breakpoint: 767,
				settings1: 'unslick',
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					centerMode: false,
					draggable: false,
				},
			},
		],
	};

	/*
	$(window).on('resize', function () {
		if ($(window).width() < 768) {
			if (
				$('#degrees_carousel, #certificates_carousel').hasClass(
					'slick-initialized'
				)
			)
				$('#degrees_carousel, #certificates_carousel').slick('unslick');
				return;
		}
		if (
			!$('#degrees_carousel, #certificates_carousel').hasClass(
				'slick-initialized'
			)
		) {
			return $('#degrees_carousel, #certificates_carousel').slick(pProps);
		}
	});
	*/
	
	$('#degrees_carousel, #certificates_carousel').slick(pProps);

	let lButtons = $('.carousel__controls a');
	if (lButtons.length) {
		for (let x = 0; x < lButtons.length; x += 1) {
			let lBtn = $(lButtons[x]);

			lBtn.on('click', function (e) {
				e.preventDefault();
				let lNext = e.target.dataset.direction === 'next';
				let lCarousel =
					'#' +
					$('.tabs-panel.is-active').attr('id').split('-')[0] +
					'_carousel';

				//console.log(lCarousel)
				if (lNext) {
					$(lCarousel).slick('slickNext');
				} else {
					$(lCarousel).slick('slickPrev');
				}
			});
		}
	}
});